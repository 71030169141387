import React from "react";

import { PageWithAuth } from "@mcp/interfaces";
import { NextPage } from "next";
import dynamic from "next/dynamic";

const NotFoundPage = dynamic(() => import("@mcp/core/dist/errors/404"), {
  ssr: false,
});

const NotFound: PageWithAuth<NextPage> = () => {
  return <NotFoundPage />;
};

NotFound.auth = false;

export default NotFound;
